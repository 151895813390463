.class-card-progress-bar,
.unit-card-progress-bar {
  .progress-bar {
    &.bg-success {
      background-color: #8b00ff !important;
      clip-path: polygon(0 0, 100% 0, calc(100% - 6px) 100%, 0 100%);
    }

    position: relative;
    z-index: 1;
  }

  &.full {
    .progress-bar {
      &.bg-success {
        clip-path: unset;
      }
    }
  }

  .progress {
    overflow: hidden;
  }
}
